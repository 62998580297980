<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-fluid
        bg-white
      "
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
        'login-reset-on': this.state == 'reset',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #F1EEFB"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="media/logos/logo.png"
              class="max-h-70px mr-4"
              alt=""
            />
          </a>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #3f0fd3"
          >
            Connexion
          </h3>
        </div>
        <div class="d-none d-lg-flex flex-column-auto flex-column" style="margin: auto auto 80px auto;">
          <img src="media/bg/auth-bg.png" style="width: 500px; max-width: 100%;">
        </div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
        "
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                    mb-3
                    d-flex
                  "
                >
                  <span class="max-h-40px mt-4">Bienvenue sur</span> <img src="media/logos/logo-dark.png" class="max-h-40px ml-2" alt="" />
                </h3>
                <p class="mb-10">Votre partenaire au quotidien dans la gestion de votre boutique de réparation</p>
                <span class="text-muted font-weight-bold font-size-h4"
                  >Nouveau ?
                  <router-link
                    id="kt_login_signup"
                    class="text-primary font-weight-bolder ml-2"
                    to="/register"
                    >Créer un compte</router-link
                  ></span
                >
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Email</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="text"
                    name="email"
                    ref="email"
                    placeholder="jean@exemple.org"
                    v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Mot de passe</label
                  >
                  <a
                    class="
                      text-primary
                      font-size-h6 font-weight-bolder
                      text-hover-primary
                      pt-5
                    "
                    href="#"
                    id="kt_login_forgot"
                    @click.prevent="showForm('forgot')"
                    >Mot de passe oublié ?</a
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="password"
                    name="password"
                    ref="password"
                    placeholder="********"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
              </div>

              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                  "
                  type="submit"
                >
                  Connexion
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Mot de passe oublié ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Entrez votre email pour réinitialiser votre mot de passe
                </p>
              </div>
              <div class="form-group">
                <input
                  v-model="forgotForm.email"
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="email"
                  placeholder="Email"
                  name="forgotEmail"
                  ref="forgotEmail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="submit"
                  ref="kt_login_forgot_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                    mr-4
                  "
                >
                  Envoyer
                </button>
                <button
                  type="button"
                  class="
                    btn btn-light-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                  "
                  @click="showForm('signin')"
                >
                  Annuler
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
          <!--begin::Reset-->
          <div class="login-form login-reset">
            <!--begin::Form-->
            <form class="form" novalidate="novalidate" id="kt_login_reset_form">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Réinitialisation du mot de passe
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Entrez votre nouveau mot de passe
                </p>
              </div>
              <div class="form-group">
                <input
                  v-model="resetForm.password"
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="password"
                  placeholder="Mot de passe"
                  name="resetPassword"
                  ref="resetPassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="submit"
                  ref="kt_login_reset_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                    mr-4
                  "
                >
                  Valider
                </button>
                <button
                  type="button"
                  class="
                    btn btn-light-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                  "
                  @click="showForm('signin')"
                >
                  Annuler
                </button>
              </div>
            </form>
          </div>
          <!--end::Reset-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        email: "",
        password: "",
      },
      forgotForm: {
        email: "",
      },
      resetForm: {
        password: "",
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");
    const reset_form = KTUtil.getById("kt_login_reset_form");

    // Signin Form
    //////////////
    this.signinFormFv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "L'adresse email est requise",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Le mot de passe est requis",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.signinFormFv.on("core.form.valid", () => {
      const email = this.form.email;
      const password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send login request
      this.$store
        .dispatch(LOGIN, { email, password })
        // go to which page after successfully login
        .then(() => {
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push("/treatments");
          }
        })
        .catch(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    this.signinFormFv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });

    // Forgot Form
    //////////////
    this.forgotFormFv = formValidation(forgot_form, {
      fields: {
        forgotEmail: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.forgotFormFv.on("core.form.valid", () => {
      const email = this.forgotForm.email;
      this.$store.dispatch(LOGOUT);

      const submitButton = this.$refs["kt_login_forgot_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      ApiService.post("/auth/forgot-password", { email })
        .then(() => {
          Swal.fire(
            "Vérifiez vos emails",
            "Un email de réinitialisation de mot de passe vient de vous être envoyé !",
            "success"
          );
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        })
        .catch(() => {
          Swal.fire(
            "Adresse email erronée",
            "Aucun compte n'est rattaché à cette adresse email",
            "error"
          );
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    // Reset Form
    /////////////
    this.resetFormFv = formValidation(reset_form, {
      fields: {
        resetPassword: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.resetFormFv.on("core.form.valid", () => {
      const password = this.resetForm.password;

      this.$store.dispatch(LOGOUT);

      const submitButton = this.$refs["kt_login_reset_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      ApiService.post(
        `/auth/reset-password?token=${this.$route.query["reset-token"]}`,
        { password }
      )
        .then(() => {
          Swal.fire(
            "Mot de passe mis à jour",
            "Vous pouvez à présent vous connecter à votre compte",
            "success"
          );
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          this.showForm("signin");
        })
        .catch(() => {
          Swal.fire(
            "Erreur",
            "Votre mot de passe n'a pas pu être réinitialisé. Veuillez réessayer.",
            "error"
          );
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    if (this.$route.query["reset-token"]) {
      this.showForm("reset");
    }
  },
  methods: {
    showForm(form) {
      this.state = form;
      const form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
  },
};
</script>
